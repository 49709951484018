import React, { useState } from 'react';
import { FaMapMarkerAlt, FaCheckCircle, FaRegCircle, FaUserCircle } from 'react-icons/fa';

const JobDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <p
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: isExpanded ? 'unset' : 2,
          cursor: 'pointer',
          color: '#333',
          fontSize: '15px',
          lineHeight: '1.6',
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {description}
      </p>
      {description.length > 100 && (
        <span
          style={{
            color: '#007AFF',
            cursor: 'pointer',
            fontSize: '14px',
            marginTop: '5px',
            display: 'inline-block',
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Show less' : 'Read more'}
        </span>
      )}
    </div>
  );
};

const JobCard = ({ job, handleViewButtonClick, handleCallJob, formatCurrency }) => {
  const [isCalled, setIsCalled] = useState(false);

  const handleCallJobClick = async (e) => {
    e.stopPropagation();
    const success = await handleCallJob(job._id);
    if (success) {
      setIsCalled(true);
    }
  };

  return (
    <div
      onClick={() => handleViewButtonClick(job._id)}
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        padding: '24px',
        border: '1px solid #E0E0E0',
        marginBottom: '20px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = '0 8px 20px rgba(0, 0, 0, 0.15)';
        e.currentTarget.style.transform = 'scale(1.02)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
        e.currentTarget.style.transform = 'scale(1)';
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        {job.profileUrl ? (
          <img
            src={job.profileUrl}
            alt={`${job.creator}'s profile`}
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              marginRight: '12px',
              objectFit: 'cover',
            }}
          />
        ) : (
          <FaUserCircle style={{ fontSize: '48px', color: '#B0B0B0', marginRight: '12px' }} />
        )}
        <div>
          <h3 style={{ color: '#000', fontSize: '18px', margin: 0, fontWeight: '600' }}>
            {job.creator}
          </h3>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
            <FaMapMarkerAlt style={{ fontSize: '14px', color: '#666', marginRight: '6px' }} />
            <p style={{ fontSize: '14px', color: '#666', margin: 0 }}>{job.location}</p>
          </div>
        </div>
      </div>
      <JobDescription description={job.description} />
      {job.mediaUrl && (
        <div style={{ marginTop: '16px' }}>
          {job.mediaUrl.match(/\.(mp4|webm|ogg)$/i) ? (
            <video controls style={{ width: '100%', borderRadius: '12px' }}>
              <source src={job.mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={job.mediaUrl}
              alt="Job media"
              style={{
                width: '100%',
                borderRadius: '12px',
                objectFit: 'cover',
              }}
              onError={(e) => (e.target.src = 'fallback-image-url.jpg')}
            />
          )}
        </div>
      )}
      <div
        style={{
          backgroundColor: '#F9F9F9',
          border: '1px solid #E0E0E0',
          width: '100%',
          padding: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '12px',
          marginTop: '16px',
        }}
      >
        {job.maxBudget > 1 && (
          <p style={{ fontSize: '16px', color: '#333', fontWeight: '500' }}>
            {formatCurrency(job.maxBudget)}
          </p>
        )}
        <p style={{ fontSize: '14px', color: '#666', display: 'flex', alignItems: 'center' }}>
          {isCalled ? (
            <FaCheckCircle style={{ fontSize: '16px', color: '#007AFF', marginRight: '6px' }} />
          ) : (
            <FaRegCircle
              style={{ fontSize: '16px', color: '#007AFF', cursor: 'pointer', marginRight: '6px' }}
              onClick={handleCallJobClick}
            />
          )}
          {isCalled ? 'Called' : job.callCount}
        </p>
      </div>
    </div>
  );
};

export default JobCard;
