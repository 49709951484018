import React from 'react';
import Accounting from '../components/Accounting'; // Assuming SeeCallers component is in the same directory
import PendingTransfers from '../components/PendingTransfers';


const AccountingPage = () => {
  return (
    <div>
      <PendingTransfers/>
      <Accounting />
    </div>
  );
};

export default AccountingPage;
