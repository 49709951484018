import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UnitsComponent from '../components/UnitsComponents';
import '../styles/Postjob.css';

const PostJob = () => {
  const [jobDetails, setJobDetails] = useState({
    description: '',
    maxBudget: '',
    minBudget: '',
    phoneNumber: '',
    agentPhoneNumber: '',
    userId: '',
    fullName: '',
    location: '',
    mediaUrl: '',
  });
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [showUnitsComponent, setShowUnitsComponent] = useState(false);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [isAgent, setIsAgent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      const { phoneNumber, _id: userId, fullName, privateName, isAgent, runorUnits } = userData;
      const nameToUse = privateName || fullName;

      setJobDetails((prevJobDetails) => ({
        ...prevJobDetails,
        userId,
        fullName: nameToUse,
        phoneNumber: isAgent ? '' : phoneNumber, // Set phoneNumber for non-agents, empty for agents
        agentPhoneNumber: isAgent ? phoneNumber : '', // Set agentPhoneNumber for agents
        runorUnits,
      }));

      setIsAgent(isAgent);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMediaUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setJobDetails((prevDetails) => ({
          ...prevDetails,
          mediaUrl: reader.result,
        }));
        setMediaPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Request Body:', jobDetails); // Log the request body

    const { runorUnits } = jobDetails;

    if (isAgent && parseInt(runorUnits) >= 300) {
      try {
        setIsSubmitting(true);
        const response = await axios.post('https://ampcds.onrender.com/jobs/post-job', jobDetails);
        navigate('/my-jobs');
      } catch (error) {
        console.error('Error submitting job:', error);
        setError('uh-oh, network error. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else if (isAgent) {
  setShowUnitsComponent(true); // Show the UnitsComponent
    } else {
      try {
        setIsSubmitting(true);
        const response = await axios.post('https://ampcds.onrender.com/jobs/post-job', jobDetails);
        navigate('/my-jobs');
      } catch (error) {
        console.error('Error submitting job:', error);
        setError('uh-oh, network error. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleCloseUnitsComponent = () => {
    setShowUnitsComponent(false);
  };


  const handlePostWithAgent = async () => {
    setLoading(true); // Show connecting state
    try {
        // Fetch approved agents from the endpoint
        const response = await axios.get('https://ampcds.onrender.com/api/approved-agents');
        const agents = response.data;

        if (agents.length > 0) {
            // Randomly select an agent's phone number
            const randomAgent = agents[Math.floor(Math.random() * agents.length)];
            const phoneNumber = randomAgent.phoneNumber;
// Construct the initial message
const initialMessage = encodeURIComponent(
  `Hello! I got your number on irunor.com as an agent. I wanted to post a job and I am ready to pay the agent fee to help me post the job. Can I give you the details of the post?`
);

// Redirect to WhatsApp with the selected phone number and initial message
const whatsappUrl = `https://wa.me/${phoneNumber}?text=${initialMessage}`;
window.location.href = whatsappUrl;
        } else {
            alert('No agents available at the moment.');
        }
    } catch (error) {
        console.error('Error fetching approved agents:', error);
        alert('Network error. Please try again.');
    } finally {
        setLoading(false); // Reset connecting state
    }
};

  
  return (
    <div className="container">
      <h2 className="header">Create Your Job</h2>
      <p className="subText">
        Need something done? Describe it, set a budget, and let us handle the rest.
        Add a visual or video to make your request even clearer! 🚀
      </p>
      <form onSubmit={handleSubmit} className="form">
        <textarea
          name="description"
          value={jobDetails.description}
          onChange={handleChange}
          placeholder="Describe your job (e.g., tasks, special instructions)"
          required
          className="textArea"
        />

        <div className="budgetContainer">
          <input
            type="number"
            name="maxBudget"
            value={jobDetails.maxBudget}
            onChange={handleChange}
            placeholder="Max Budget"
            required
            className="input"
          />
          <input
            type="number"
            name="minBudget"
            value={jobDetails.minBudget}
            onChange={handleChange}
            placeholder="Min Budget"
            className="input"
          />
        </div>

        {isAgent ? (
  <input
    type="text"
    name="phoneNumber"
    value={jobDetails.phoneNumber}
    onChange={handleChange}
    placeholder="Enter your phone number"
    className="inputFullWidth"
    required
  />
) : (
  <input
    type="hidden" // Hide input for non-agents
    name="phoneNumber"
    value={jobDetails.phoneNumber}
    onChange={handleChange}
  />
)}


        <input
          type="text"
          name="location"
          value={jobDetails.location}
          onChange={handleChange}
          placeholder="Location"
          className="inputFullWidth"
        />

        <div className="mediaContainer">
          <label htmlFor="mediaUpload" className="mediaLabel">
            Upload Image/Video:
          </label>
          <input
            type="file"
            accept="image/*, video/*"
            onChange={handleMediaUpload}
            id="mediaUpload"
            className="mediaInput"
          />
        </div>

        {mediaPreview && (
          <div className="mediaPreviewContainer">
            {jobDetails.mediaUrl.includes('video') ? (
              <video controls className="mediaPreview">
                <source src={mediaPreview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={mediaPreview} alt="Preview" className="mediaPreview" />
            )}
          </div>
        )}

        <button
          type="submit"
          className={`button ${isSubmitting ? 'buttonDisabled' : ''}`}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Job'}
        </button>

        {error && <p className="errorMessage">{error}</p>}
        <button
            onClick={handlePostWithAgent}
            disabled={loading}
            style={{
                padding: '10px 20px',
                fontSize: '16px',
                backgroundColor: loading ? '#ccc' : '#ccc',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: loading ? 'not-allowed' : 'pointer',
                marginTop: '-40px',
                marginBottom: '40px',
                width: '100%', 
            }}
        >
            {loading ? 'Connecting...' : 'Post with an Agent'}
        </button>
      </form>
      
      {showUnitsComponent && (
        <div className="unitsComponentContainer">
          <UnitsComponent onClose={handleCloseUnitsComponent} />
        </div>
      )}
    </div>
  );
};

export default PostJob;
