import React from 'react';
import AgentPromoDetails from '../components/AgentPromoDetails';
import '../styles/AgentMarketingPage.css';

const AgentMarketingPage = () => {
  return (
    <div className="agent-marketing-page">
      <AgentPromoDetails />
    </div>
  );
};

export default AgentMarketingPage;

