import React from 'react';
import AdsConfirm from '../components/ConfirmsAds'; // Adjust path if needed

const AdsConfirmPage = () => {
  return (
    <div>
      <AdsConfirm />
    </div>
  );
};

export default AdsConfirmPage;
