import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ApproveAgents = () => {
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(false);

    // Check authorization based on phoneNumber in local storage
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user?.phoneNumber === '08106292993' || user?.phoneNumber === '07041118651') {
            setIsAuthorized(true);
        } else {
            setIsAuthorized(false);
        }
    }, []);

    // Fetch unapproved agents
    const fetchAgents = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get('https://ampcds.onrender.com/api/unapproved-agents');
            setAgents(response.data);
        } catch (err) {
            setError('Failed to fetch agents.');
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    // Approve an agent
    const approveAgent = async (agentId) => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.patch(`https://ampcds.onrender.com/api/agent-promo/${agentId}/approve`);
            alert(response.data.message); // Notify success
            fetchAgents(); // Refresh the list
        } catch (err) {
            const errorMessage = err.response?.data?.error || 'An error occurred while approving the agent.';
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    // Fetch agents on component mount
    useEffect(() => {
        if (isAuthorized) {
            fetchAgents();
        }
    }, [isAuthorized]);

    if (!isAuthorized) {
        return <div style={styles.container}><h1 style={styles.error}>Forbidden</h1></div>;
    }

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Approve Agents</h1>
            {loading && <p style={styles.message}>Loading...</p>}
            {error && <p style={styles.error}>{error}</p>}
            {agents.length === 0 && !loading && (
                <p style={styles.message}>No unapproved agents available.</p>
            )}
            <ul style={styles.list}>
                {agents.map((agent) => (
                    <li key={agent._id} style={styles.listItem}>
                        <div>
                            <strong>{agent.firstName} {agent.lastName}</strong>
                            <p>{agent.phoneNumber}</p>
                            <p>Location of Agency: <strong>{agent.agentLocation}</strong></p>
                            <p>Type: <strong>{agent.operationBase}</strong></p>
                            <p>House Address: {agent.houseAddress}</p>
                        </div>
                        <button
                            style={styles.button}
                            onClick={() => approveAgent(agent._id)}
                        >
                            Approve
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

// Styles
const styles = {
    container: {
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#333',
    },
    message: {
        textAlign: 'center',
        color: '#555',
    },
    error: {
        textAlign: 'center',
        color: '#d9534f',
    },
    list: {
        listStyleType: 'none',
        padding: 0,
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#f9f9f9',
        borderRadius: '5px',
        padding: '10px',
        marginBottom: '10px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    },
    button: {
        padding: '10px 15px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontWeight: 'bold',
        transition: 'background-color 0.2s ease',
    },
};

export default ApproveAgents;
