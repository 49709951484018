import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/UserJobs.css';

const ConfirmAdSetup = () => {
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const jobId = localStorage.getItem('toBePromoted');

    if (!jobId) {
      console.error('No job selected for promotion.');
      setIsProcessing(false);
      return;
    }

    const submitPromotion = async () => {
      try {
        const response = await axios.post(
          `https://ampcds.onrender.com/jobs/promote-job/${jobId}`
        );
        if (response.status === 200) {
          window.location.href = '/my-jobs'; // Redirect to /my-jobs on success
        } else {
          throw new Error('Failed to set up the ad.');
        }
      } catch (err) {
        console.error('Error setting up the ad:', err);
        // Retry after a delay
        setTimeout(submitPromotion, 3000); // Retry every 3 seconds
      }
    };

    submitPromotion();
  }, []);

  return (
    <div className="confirm-ad-setup-container">
      {isProcessing && (
        <div className="processing-modal">
          <div className="loader"></div>
          <h2>Finishing things and setting up your ad...</h2>
        </div>
      )}
    </div>
  );
};

export default ConfirmAdSetup;
