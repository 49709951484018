import React from 'react';
import '../styles/AgentPromo.css';

const AgentPromo = () => {
  return (
    <div className="agent-promo-container">
      <div className="agent-promo-content">
        <h1 className="promo-title">"Turn Your Connections into Cash!"</h1>
        <p className="promo-message">
          Ready to grow your business or start something new? Join <strong>irunor</strong>,
          the platform where you connect people, servicemen, and employees to jobs
          and opportunities that matter. Be the link that builds a better tomorrow!
        </p>
        <div className="promo-buttons">
          <button className="learn-more-btn" onClick={() => window.location.href = '/agent-marketing'}>
            Learn More
          </button>
          <button className="get-started-btn" onClick={() => window.location.href = '/agent-marketing'}>
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentPromo;

