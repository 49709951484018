import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import JobSkeleton from './JobSkeleton'; // Importing the skeleton loader
import '../styles/ServicemenList.css';

const ServicemenList = () => {
  const [servicemen, setServicemen] = useState([]);
  const [filteredServicemen, setFilteredServicemen] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServicemen = async () => {
      try {
        const localServicemen = localStorage.getItem('irunor-servicemen');
        
        if (localServicemen) {
          const servicemenData = JSON.parse(localServicemen);
          setServicemen(servicemenData);
          setFilteredServicemen(servicemenData);
          setLoading(false);
        }

        const servicemenResponse = await axios.get('https://ampcds.onrender.com/api/auth/servicemen');
        if (servicemenResponse.data && servicemenResponse.data.servicemen) {
          const updatedServicemen = servicemenResponse.data.servicemen.map(serviceman => ({
            ...serviceman,
            displayName: serviceman.businessName || serviceman.fullName,
          }));

          setServicemen(updatedServicemen);
          setFilteredServicemen(updatedServicemen);
          localStorage.setItem('irunor-servicemen', JSON.stringify(updatedServicemen));
        } else {
          throw new Error("Invalid data format");
        }
      } catch (err) {
        setError('Network error, Reconnecting...');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchServicemen();
  }, []);

  // Handle search and filters
  useEffect(() => {
    const applyFilters = () => {
      const lowerQuery = searchQuery.toLowerCase();

      const filtered = servicemen.filter(serviceman => 
        (!searchQuery || serviceman.displayName?.toLowerCase().includes(lowerQuery)) &&
        (!selectedService || serviceman.serviceRendered === selectedService) &&
        (!selectedLocation || serviceman.currentLocation === selectedLocation) &&
        serviceman.displayName && serviceman.serviceRendered && serviceman.serviceDescription && serviceman.currentLocation
      );

      setFilteredServicemen(filtered);
    };

    applyFilters();
  }, [searchQuery, selectedService, selectedLocation, servicemen]);

  const handleServicemanClick = (serviceman) => {
    navigate(`/user-profile/${serviceman.userId}`);
  };

  if (loading) {
    return (
      <div className="loading-skeletons">
        {Array.from({ length: 5 }).map((_, index) => (
          <JobSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!filteredServicemen || filteredServicemen.length === 0) {
    return <div className="no-servicemen">No servicemen match your criteria.</div>;
  }

  // Get unique values for dropdown filters
  const uniqueServices = [...new Set(servicemen.map(s => s.serviceRendered).filter(Boolean))];
  const uniqueLocations = [...new Set(servicemen.map(s => s.currentLocation).filter(Boolean))];

  return (
    <div className="servicemen-list-container">
      <div className="filters-container">
        <input 
          type="text" 
          placeholder="Search by name..." 
          className="search-bar" 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} 
        />
        <select 
          className="filter-dropdown" 
          value={selectedService} 
          onChange={(e) => setSelectedService(e.target.value)}
        >
          <option value="">All Services</option>
          {uniqueServices.map(service => (
            <option key={service} value={service}>
              {service}
            </option>
          ))}
        </select>
        <select 
          className="filter-dropdown" 
          value={selectedLocation} 
          onChange={(e) => setSelectedLocation(e.target.value)}
        >
          <option value="">All Locations</option>
          {uniqueLocations.map(location => (
            <option key={location} value={location}>
              {location}
            </option>
          ))}
        </select>
      </div>

      <ul className="servicemen">
        {filteredServicemen.map(serviceman => (
          <li 
            key={serviceman._id} 
            className="serviceman-card" 
            onClick={() => handleServicemanClick(serviceman)}
          >
            <div className="service-circle">{serviceman.serviceRendered}</div>
            <p className="serviceman-name-preview">{serviceman.displayName}</p>
            <p className="serviceman-description">
              {serviceman.serviceDescription?.length > 100
                ? `${serviceman.serviceDescription.slice(0, 100)}...`
                : serviceman.serviceDescription}
            </p>
            <div className="card-footer">
              <span className="serviceman-location">{serviceman.currentLocation}</span>
              <span className="serviceman-rating">
                {'★'.repeat(5)}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ServicemenList;
