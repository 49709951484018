import React from 'react';
import PricingComponent from '../components/Credits'; // Adjust the import path as necessary

const CreditsPage = () => {
  return (
    <div>
      <h1>Credits</h1>
      
      <PricingComponent /> {/* Insert the PricingComponent here */}

    </div>
  );
};

export default CreditsPage;
