import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TransfersAndPayments = () => {
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState('');

  const storedUserData = localStorage.getItem('user');
  const userData = storedUserData ? JSON.parse(storedUserData) : {};
  const phoneNumber = userData.phoneNumber;

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`https://ampcds.onrender.com/api/transactions/${phoneNumber}`);
        const { transfers, payments } = response.data;

        // Combine and map the transactions
        const combinedTransactions = [
          ...transfers.map(t => ({
            ...t,
            type: t.recipientPhoneNumber === phoneNumber ? 'Received' : 'Sent',
            color: t.recipientPhoneNumber === phoneNumber ? '#4caf50' : '#f44336',
            date: new Date(t.transferDate), // Convert transferDate to Date object
            details: t.recipientPhoneNumber === phoneNumber ?
              `Received from ${t.senderPhoneNumber}` :
              `Sent to ${t.recipientPhoneNumber}`,
          })),
          ...payments.map(p => ({
            ...p,
            type: 'Payment',
            color: '#2196f3',
            date: new Date(p.date), // Convert date to Date object
            details: `Credit top-up`,
          })),
        ].sort((a, b) => b.date - a.date); // Sort by date, most recent first

        setTransactions(combinedTransactions);
        setError('');
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Error fetching data');
      }
    };

    fetchTransactions();
  }, [phoneNumber]);

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Your Transactions</h2>
      {error && <p style={styles.error}>{error}</p>}
      
      <ul style={styles.list}>
        {transactions.length > 0 ? (
          transactions.map((transaction, index) => (
            <li key={index} style={{ ...styles.listItem, borderColor: transaction.color }}>
              <span style={{ ...styles.amount, color: transaction.color }}>
                {transaction.type === 'Received' ? '+' : '-'}₦{transaction.amount}
              </span>
              <span style={styles.details}>
                {transaction.details}
              </span>
              <span style={styles.date}>
                {transaction.date instanceof Date && !isNaN(transaction.date)
                  ? transaction.date.toLocaleDateString()
                  : 'Invalid date'}
              </span>
            </li>
          ))
        ) : (
          <p style={styles.noData}>No transactions found</p>
        )}
      </ul>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    width: '90%',
    background: 'linear-gradient(to bottom right, #e0f7fa, #ffffff)',
    margin: '40px auto',
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    fontFamily: '"Helvetica Neue", Arial, sans-serif',
    color: '#333',
  },
  header: {
    fontSize: '26px',
    marginBottom: '20px',
    fontWeight: '500',
    textAlign: 'center',
    color: '#007aff',
  },
  error: {
    color: '#f44336',
    fontSize: '16px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  list: {
    listStyleType: 'none',
    paddingLeft: '0',
    margin: '0',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px',
    borderLeft: '5px solid',
    borderRadius: '8px',
    marginBottom: '10px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.05)',
  },
  amount: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  details: {
    fontSize: '16px',
    color: '#555',
    flex: '1',
    paddingLeft: '10px',
  },
  date: {
    fontSize: '14px',
    color: '#999',
  },
  noData: {
    fontSize: '18px',
    color: '#777',
    textAlign: 'center',
  },
};

export default TransfersAndPayments;
