import React, { useState, useEffect } from 'react';
import '../styles/AccountOverview.css';

const AccountOverview = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
      } catch (error) {
        console.error('Error parsing user information:', error);
      }
    }
  }, []);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  };

  if (!user) {
    return <p className="loading">Loading user information...</p>;
  }

  return (
    <section className="account-overview-container">
      <div className="account-section">
        <h2 className="user-name">{user.fullName}</h2>
        <p className="account-number">{user.phoneNumber}</p>
      </div>

      <div className="balance-section">
        <h2 className="balance">{formatCurrency(user.runorUnits)}</h2>
      </div>
    </section>
  );
};

export default AccountOverview;


