import React from 'react';
import ApproveAgents from '../components/ApproveAgents'; // Adjust the path if necessary

const ApproveAgentsPage = () => {
    return (
        <div style={pageStyles.container}>
            <h1 style={pageStyles.header}>Agent Approval Dashboard</h1>
            <ApproveAgents />
        </div>
    );
};

// Page-level styles
const pageStyles = {
    container: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f0f0',
        padding: '20px',
    },
    header: {
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: '20px',
        color: '#444',
    },
};

export default ApproveAgentsPage;
