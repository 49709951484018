import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/PendingTransfers.css';

const PendingWithdrawals = () => {
  const [pendingWithdrawals, setPendingWithdrawals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [approving, setApproving] = useState(null);

  useEffect(() => {
    const fetchPendingWithdrawals = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://ampcds.onrender.com/api/withdrawals/pending-or-failed'); // Updated endpoint
        setPendingWithdrawals(response.data.pendingWithdrawals || []); // Ensure it's an array
        setLoading(false);
      } catch (err) {
        console.error('Error fetching pending withdrawals:', err);
        setError('Error fetching pending withdrawals');
        setPendingWithdrawals([]); // Set to an empty array on error
        setLoading(false);
      }
    };
  
    fetchPendingWithdrawals();
  }, []);
  

  // Approve withdrawal by calling the initiate endpoint
  const handleApprove = async (withdrawalId) => {
    try {
      setApproving(withdrawalId); // Set the current approving state to disable the button
      const response = await axios.post('https://ampcds.onrender.com/api/withdraw/initiate', { withdrawalId }); // Updated endpoint
      alert(`Withdrawal Approved: ${response.data.transferResponse.status}`);
      // Refresh the pending withdrawals list after approval
      setPendingWithdrawals(pendingWithdrawals.filter((withdrawal) => withdrawal._id !== withdrawalId));
    } catch (err) {
      alert('Error approving withdrawal');
    } finally {
      setApproving(null); // Reset approving state
    }
  };

  return (
    <div className="container">
      <h2>Pending Withdrawals</h2>
      {loading && <p>Loading pending withdrawals...</p>}
      {error && <p className="error">{error}</p>}

      {pendingWithdrawals.length > 0 ? (
        <table className="transfers-table">
          <thead>
            <tr>
              <th>Amount (NGN)</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pendingWithdrawals.map((withdrawal) => (
              <tr key={withdrawal._id}>
                <td>{withdrawal.amount}</td>
                <td>{withdrawal.status}</td>
                <td>
                  <button
                    className="approve-button"
                    disabled={approving === withdrawal._id} // Disable button while approving
                    onClick={() => handleApprove(withdrawal._id)}
                  >
                    {approving === withdrawal._id ? 'Approving...' : 'Approve'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No pending withdrawals found</p>
      )}
    </div>
  );
};

export default PendingWithdrawals;
