import React from 'react';

// Function to parse and format text
const parseText = (text) => {
  // Split text into paragraphs or lines
  const paragraphs = text.split(/\n\s*\n/);

  return paragraphs.map((paragraph, index) => {
    // Heading detection (Markdown-style)
    if (/^#{1,6}\s/.test(paragraph)) {
      const [headingLevel, ...rest] = paragraph.split(/\s+/);
      const level = headingLevel.length;
      const headingText = rest.join(' ');
      return React.createElement(`h${level}`, { key: index }, headingText);
    }
    // Blockquote detection
    else if (/^>\s/.test(paragraph)) {
      const blockquoteText = paragraph.replace(/^>\s*/, '');
      return <blockquote key={index}>{parseText(blockquoteText)}</blockquote>;
    }
    // Code block detection (fenced blocks with triple backticks)
    else if (/^```/.test(paragraph)) {
      const codeBlock = paragraph.replace(/^```[a-z]*\n/, '').replace(/```$/, '');
      return <pre key={index}><code>{codeBlock}</code></pre>;
    }
    // Inline code detection (`code`)
    else if (/`[^`]+`/.test(paragraph)) {
      const inlineFormatted = paragraph.split(/(`[^`]+`)/).map((part, i) => {
        if (/^`[^`]+`$/.test(part)) {
          return <code key={i}>{part.replace(/`/g, '')}</code>;
        }
        return <span key={i}>{part}</span>;
      });
      return <p key={index}>{inlineFormatted}</p>;
    }
    // Ordered list detection
    else if (/^\d+\.\s/.test(paragraph)) {
      const listItems = paragraph.split(/\n/).map((item, i) => (
        <li key={i}>{item.replace(/^\d+\.\s*/, '')}</li>
      ));
      return <ol key={index}>{listItems}</ol>;
    }
    // Unordered list detection
    else if (/^\s*[-*+]\s/.test(paragraph)) {
      const listItems = paragraph.split(/\n/).map((item, i) => (
        <li key={i}>{item.replace(/^\s*[-*+]\s*/, '')}</li>
      ));
      return <ul key={index}>{listItems}</ul>;
    }
    // Combined bold and italic text detection (**_text_** or __*text*__)
    else if (/\*\*\*.*?\*\*\*|___.*?___/.test(paragraph)) {
      const combinedFormatted = paragraph.split(/(\*\*\*.*?\*\*\*|___.*?___)/).map((part, i) => {
        if (/^\*\*\*(.*?)\*\*\*$/.test(part) || /^___(.*?)___$/.test(part)) {
          return <strong key={i}><em>{part.replace(/\*\*\*|___/g, '')}</em></strong>;
        }
        return <span key={i}>{part}</span>;
      });
      return <p key={index}>{combinedFormatted}</p>;
    }
    // Bold text detection (**text** or __text__)
    else if (/\*\*.*?\*\*|__.*?__/.test(paragraph)) {
      const boldFormatted = paragraph.split(/(\*\*.*?\*\*|__.*?__)/).map((part, i) => {
        if (/^\*\*(.*?)\*\*$/.test(part) || /^__(.*?)__$/.test(part)) {
          return <strong key={i}>{part.replace(/\*\*|__/g, '')}</strong>;
        }
        return <span key={i}>{part}</span>;
      });
      return <p key={index}>{boldFormatted}</p>;
    }
    // Italic text detection (*text* or _text_)
    else if (/\*.*?\*|_.*?_/.test(paragraph)) {
      const italicFormatted = paragraph.split(/(\*.*?\*|_.*?_)/).map((part, i) => {
        if (/^\*(.*?)\*$/.test(part) || /^_(.*?)_$/.test(part)) {
          return <em key={i}>{part.replace(/\*|_/g, '')}</em>;
        }
        return <span key={i}>{part}</span>;
      });
      return <p key={index}>{italicFormatted}</p>;
    }
    // Link detection ([text](url))
    else if (/\[.*?\]\(.*?\)/.test(paragraph)) {
      const linkFormatted = paragraph.split(/(\[.*?\]\(.*?\))/).map((part, i) => {
        if (/^\[.*?\]\(.*?\)$/.test(part)) {
          const text = part.match(/\[(.*?)\]/)[1];
          const href = part.match(/\((.*?)\)/)[1];
          return <a key={i} href={href}>{text}</a>;
        }
        return <span key={i}>{part}</span>;
      });
      return <p key={index}>{linkFormatted}</p>;
    }
    // Plain text with line breaks (fallback)
    else {
      const plainFormatted = paragraph.split(/\n/).map((line, i) => (
        <React.Fragment key={i}>
          {line}
          <br />
        </React.Fragment>
      ));
      return <p key={index}>{plainFormatted}</p>;
    }
  });
};

const FormatText = ({ text }) => {
  return <div>{parseText(text)}</div>;
};

export default FormatText;
