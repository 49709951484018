/* global FlutterwaveCheckout */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const PricingComponent = () => {
  const [units, setUnits] = useState(1000); // Default minimum units is 1000
  const [error, setError] = useState('');
  const serviceFee = 100;
  const unitPrice = 0.9975;
  const navigate = useNavigate();

  const calculateTotalPrice = (units) => (units * unitPrice) + serviceFee;

  const handleUnitsChange = (e) => {
    const value = parseInt(e.target.value, 10);

    if (isNaN(value)) {
      setUnits(''); // Clear the input if it's not a valid number
      setError('Please enter a valid number of units.');
    } else if (value >= 1000) {
      setUnits(value);
      setError(''); // Clear any previous error
    } else {
      setUnits(value); // Allow the number to be entered even if below 1000 to provide immediate feedback
      setError('Minimum amount is 1000 units.');
    }
  };

  const handlePayment = () => {
    if (units < 1000) {
      setError('Please enter a valid amount of units (minimum 1000).');
      return;
    }

    const storedUser = JSON.parse(localStorage.getItem('user'));

    if (!storedUser) {
      console.error("Please log in to continue.");
      return;
    }

    const { phoneNumber, fullName, email } = storedUser;
    const totalPrice = calculateTotalPrice(units);

    const paymentModal = FlutterwaveCheckout({
      public_key: "FLWPUBK-0dd317bda06c1094149874425aa55613-X",
      tx_ref: `txref-${Date.now()}`,
      amount: totalPrice,
      currency: "NGN",
      payment_options: "card, banktransfer, ussd",
      meta: {
        source: "PricingComponent",
        units,
      },
      customer: {
        email: email || "user@example.com",
        phone_number: phoneNumber,
        name: fullName,
      },
      customizations: {
        title: "Purchase Units",
        description: `You are purchasing ${units} units. A ₦100 service fee is included.`,
        logo: "https://checkout.flutterwave.com/assets/img/rave-logo.png",
      },
      callback: function (data) {

        if (data.status === "successful") {
          const amountExcludingServiceFee = data.amount - serviceFee;
          const unitsPurchased = Math.floor(amountExcludingServiceFee / unitPrice);

          axios.post('https://ampcds.onrender.com/api/transaction', {
            phoneNumber,
            amount: amountExcludingServiceFee,
            tx_ref: data.tx_ref,
            status: data.status,
          })
          .then(() => {
            // Update local storage with the new amount of units
            const newRunorUnits = (storedUser.runorUnits || 0) + unitsPurchased;
            localStorage.setItem('user', JSON.stringify({
              ...storedUser,
              runorUnits: newRunorUnits
            }));

            // Close the payment modal
            paymentModal.close();

            // Redirect to post-job page
            navigate('/post-job');
          })
          .catch((error) => {
            console.error("Error sending transaction details:", error);
          });
        }
      },
      onclose: function () {
        console.log("Payment process closed.");
      }
    });
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Purchase Units</h1>
      <p style={styles.description}>Acquire 1000 units for just ₦997.50.</p>
      <p style={styles.description}>A transparent service fee of ₦100 is included.</p>
      <input
        type="number"
        value={units}
        onChange={handleUnitsChange}
        min="1000"
        style={styles.input}
        aria-label="Units"
        placeholder="Enter units"
      />
      {error && <p style={styles.error}>{error}</p>}
      <p style={styles.total}>Total: ₦{calculateTotalPrice(units).toFixed(2)}</p>
      <button onClick={handlePayment} style={styles.button}>
        Buy Now
      </button>
    </div>
  );
};

const styles = {
  container: {
    padding: '40px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    maxWidth: '360px',
    margin: '80px auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontWeight: '600',
    color: '#333',
    fontSize: '24px',
    marginBottom: '16px',
    textAlign: 'center',
  },
  description: {
    color: '#555',
    fontSize: '16px',
    marginBottom: '12px',
    textAlign: 'center',
  },
  input: {
    width: '100%',
    padding: '12px',
    fontSize: '18px',
    marginBottom: '20px',
    borderRadius: '8px',
    border: '1px solid #ddd',
    textAlign: 'center',
  },
  total: {
    fontSize: '20px',
    color: '#111',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: '24px',
  },
  button: {
    width: '100%',
    padding: '14px',
    fontSize: '18px',
    backgroundColor: '#007aff',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'background-color 0.3s ease',
  },
  error: {
    color: 'red',
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '16px',
  }
};

export default PricingComponent;
