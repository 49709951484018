import React from 'react';
import UpgradeToUnlimitedCalls from '../components/Subs'; // Import the UpgradeToUnlimitedCalls component

const UpgradePage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.title}>
          Unlock Your Future, Don’t Wait for Opportunities
        </h2>
        <p style={styles.subtitle}>
          Jobs are the gateway to success. We bring them to you—straight to your phone.
        </p>
      </div>
      <div style={styles.content}>
        <UpgradeToUnlimitedCalls /> {/* Render the UpgradeToUnlimitedCalls component */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '40px 20px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    maxWidth: '900px',
    margin: '0 auto',
  },
  header: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  title: {
    fontSize: '32px',
    color: '#333',
    fontWeight: '600',
    marginBottom: '10px',
    lineHeight: '1.2',
    letterSpacing: '0.5px',
  },
  subtitle: {
    fontSize: '18px',
    color: '#555',
    marginBottom: '30px',
    fontWeight: '400',
    maxWidth: '700px',
    margin: '0 auto',
  },
  content: {
    marginTop: '50px',
    textAlign: 'center',
  },
};

export default UpgradePage;
