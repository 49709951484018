import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UpgradeToUnlimitedCalls = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            const currentUrl = window.location.pathname;
            navigate(`/login?redirectUrl=${encodeURIComponent(currentUrl)}`);
        }
    }, [navigate]);

    const handleUpgrade = () => {
        const user = localStorage.getItem('user');
        if (!user) return;
        console.log('Upgrade logic goes here');
    };

    const user = localStorage.getItem('user');
    let isSubscribed = false;
    if (user) {
        const userData = JSON.parse(user);
        if (userData.isSubscribed && !userData.teamKey) {
            isSubscribed = true;
        }
    }

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Unlock Jobs, Access Opportunies</h2>
            <div style={styles.features}>
                {isSubscribed ? (
                    <p style={styles.subscribedText}>
                        You’re covered! Enjoy unlimited calls and jobs this month.
                    </p>
                ) : (
                    <a 
                        href="https://flutterwave.com/pay/cgku4fgzrdyc" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={styles.link} 
                        onClick={handleUpgrade}
                    >
                        <button style={styles.button}>Get Started - ₦2,365</button>
                    </a>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        margin: '50px auto',
        width: '90%',
        fontFamily: 'Helvetica, Arial, sans-serif',
        maxWidth: '600px',
    },
    title: {
        fontSize: '24px',
        color: '#333',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    features: {
        backgroundColor: '#f9f9f9',
        borderRadius: '12px',
        padding: '20px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
    },
    link: {
        textDecoration: 'none',
    },
    subscribedText: {
        fontSize: '16px',
        color: '#333',
        marginTop: '20px',
        backgroundColor: '#e9f7fe',
        padding: '15px',
        borderRadius: '8px',
        fontWeight: '500',
    },
    button: {
        display: 'inline-block',
        backgroundColor: '#007aff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '600',
        padding: '12px 20px',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease, transform 0.2s ease',
        textTransform: 'uppercase',
    },
    buttonHover: {
        backgroundColor: '#0051a2',
        transform: 'scale(1.05)',
    },
};

export default UpgradeToUnlimitedCalls;
