import React from 'react';
import '../styles/JobDetailsFooter.css'; // Optional: Separate CSS for footer-specific styles.

const JobDetailsFooter = ({ job }) => {
    return (
        <div className="job-details-footer">
            <div className="job-budget">
                <p>₦{job.minBudget} - ₦{job.maxBudget}</p>
            </div>
            <div className="job-call-count">
                <p>{job.callCount} calls</p>
            </div>
        </div>
    );
};

export default JobDetailsFooter;
