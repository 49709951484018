import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactTyped } from 'react-typed'; // Ensure this is installed via npm
import '../styles/Marketing.css';

const Marketing = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger fade-in animation
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  return (
    <div className={`marketing-container ${isVisible ? 'fade-in' : ''}`}>
      <div className="marketing-content">
        <p className="headline">
          <ReactTyped
            strings={[
              'Your Next Opportunity',
              'Jobs',
              'New Jobs',
              'More Jobs',
              'Get Hired - By the World',
              'Jobs',
              'High paying Jobs',
              'Your price, place - Your terms',
              'Get Hired - By the World',
            ]}
            typeSpeed={80}
            backSpeed={30}
            backDelay={1000}
            loop
            style={{ color: 'inherit' }}
          />
        </p>
        
        
        <div className="action-buttons">
        <button
            className="get-started-btn"
            onClick={() => navigate('/upgrade-page')}
          >
            Sign up
          </button>
          
          <button
            className="learn-more-btn"
            onClick={() => navigate('/servicemen-page')}
          >
            Learn More
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default Marketing;
