import React from 'react';
import ContactNumbers from '../components/ContactNumbers';
const SupportFeedbackPage = () => {
  return (
    <div>
      <ContactNumbers />
    </div>
  );
};

export default SupportFeedbackPage;
