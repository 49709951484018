import React from 'react';
import '../styles/UnitsComponent.css';

const BoostVisibility = ({ jobId, userId, onClose }) => {
  const proceedBoost = () => {
    if (jobId) {
      // Save the jobId and userId to localStorage or send them to the server if needed
      localStorage.setItem('toBePromoted', jobId);
      window.open('https://flutterwave.com/pay/vedl3unygfcv', '_blank'); // External link for payment
    }
  };

  return (
    <div className="units-component">
      <p>
        Boosting this job will place it at the top of the job list,
        ensuring it gets more visibility to potential candidates. The cost
        is ₦5000.
      </p>
      <div className="button-container">
        <button className="close-button" onClick={onClose}>Close</button>
        <button className="top-up-button" onClick={proceedBoost}>Pay</button>
      </div>
    </div>
  );
};

export default BoostVisibility;
