import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/UserJobs.css';
import BoostVisibility from './BoostVisibility';

const UserJobs = () => {
  const [userJobs, setUserJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (!userData) return;

    try {
      const { _id } = JSON.parse(userData);

      axios
        .get('https://ampcds.onrender.com/jobs/user-jobs', {
          params: { userId: _id },
        })
        .then((response) => {
          // Sort jobs: promoted first, then by date (newest first)
          const sortedJobs = response.data.sort((a, b) => {
            if (a.isPromoted !== b.isPromoted) return b.isPromoted - a.isPromoted;
            return new Date(b.date) - new Date(a.date);
          });
          setUserJobs(sortedJobs);
          setIsLoading(false);
        })
        .catch((error) => console.error('Error fetching user jobs:', error));
    } catch (error) {
      console.error('Error parsing user data:', error);
    }
  }, []);

  const handlePauseResumeClick = async (jobId, taken) => {
    const endpoint = taken
      ? `https://ampcds.onrender.com/jobs/not-taken-job/${jobId}`
      : `https://ampcds.onrender.com/jobs/taken-job/${jobId}`;

    try {
      await axios.post(endpoint);
      setUserJobs((prevJobs) =>
        prevJobs.map((job) =>
          job._id === jobId ? { ...job, taken: !taken } : job
        )
      );
    } catch (error) {
      console.error('Error updating job status:', error);
    }
  };

  const handleBoostClick = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const handleShowMore = (jobId) => {
    navigate(`/job-details/${jobId}`);
  };

  const handleHideClick = (jobId) => {
    navigate(`/callers-page/${jobId}`);
  };

  return (
    <div className="user-jobs-container" style={{ marginBottom: '90px' }}>
      {isLoading ? (
        <div className="loader">Loading...</div>
      ) : userJobs.length === 0 ? (
        <div className="no-jobs-message">
          <p>You are yet to create a job.</p>
          <Link to="/post-job" className="get-started-link">
            Get started
          </Link>
        </div>
      ) : (
        userJobs.map((job) => (
          <div
            key={job._id}
            className={`job-card ${job.isPromoted ? 'promoted-job' : ''}`}
          >
            <h3>{job.callCount} Servicemen called you.</h3>
            <p>
              {job.description.length > 100
                ? `${job.description.slice(0, 100)}...`
                : job.description}
              {job.description.length > 100 && (
                <span
                  className="show-more-text"
                  onClick={() => handleShowMore(job._id)}
                  style={{
                    color: '#007bff',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  Show more
                </span>
              )}
            </p>
            <p>Budget: ₦{job.minBudget} - ₦{job.maxBudget}</p>
            <p>Date: {new Date(job.date).toLocaleDateString()}</p>
            {job.isPromoted && <p className="promoted-badge">Promoted</p>}
            <div className="button-container">
              <button
                style={{
                  marginRight: '10px',
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  color: 'black',
                  width: '100px',
                }}
                onClick={() => handlePauseResumeClick(job._id, job.taken)}
              >
                {job.taken ? 'Resume' : 'Pause'}
              </button>
              <button onClick={() => handleHideClick(job._id)}>Applicants</button>
              <button
                style={{
                  marginLeft: '10px',
                  backgroundColor: job.isPromoted ? '#ccc' : '#28a745',
                  color: job.isPromoted ? 'black' : 'white',
                  border: 'none',
                  width: '120px',
                }}
                onClick={() => !job.isPromoted && handleBoostClick(job)}
                disabled={job.isPromoted}
              >
                {job.isPromoted ? 'Boosted' : 'Boost Visibility'}
              </button>
            </div>
          </div>
        ))
      )}

      {showModal && selectedJob && (
        <BoostVisibility
          jobId={selectedJob._id}
          userId={JSON.parse(localStorage.getItem('user'))._id}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default UserJobs;
