import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/MarketingPop.css'; // Changed to module for scoped styling

const MarketingPop = () => {
  const navigate = useNavigate();

  const handleUpgradeClick = () => {
    navigate('/upgrade-page');
  };

  const handleLearnMoreClick = () => {
    navigate('/servicemen-page');
  };

  return (
    <div className={styles.marketingContainer}>
      <h3 className={styles.heading}>Showcase yourself, Perfect Jobs for you!</h3>
      <p className={styles.subheading}>Premium users get jobs via SMS.</p>
      <div className={styles.buttonContainer}>
        <button onClick={handleUpgradeClick} className={styles.upgradeButton}>
          Upgrade Now
        </button>
        <button onClick={handleLearnMoreClick} className={styles.learnMoreButton}>
          Learn More
        </button>
      </div>
    </div>
  );
};

export default MarketingPop;
