import React from 'react';
import '../styles/JobSkeleton.css';

const JobSkeleton = () => {

  return (
    <div className="skeletonCard">
      <div className="skeletonHeader"></div>
      <div className="skeletonBody"></div>
      <div className="skeletonFooter"></div>
    </div>
  );
};

export default JobSkeleton;
