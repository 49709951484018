import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaExchangeAlt, FaMoneyCheckAlt } from 'react-icons/fa';
import axios from 'axios';
import AccountOverview from './AccountOverview';
import TransfersAndPayments from './TransactionList';
import '../styles/UserProfile.css'; // Import the CSS file

const UserProfile = () => {
  const navigate = useNavigate();

  // Retrieve user data from local storage
  const storedUserData = localStorage.getItem('user');
  const userData = storedUserData ? JSON.parse(storedUserData) : {};
  const { phoneNumber, runorUnits } = userData;

  // State variables for the transfer process
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [amountToSend, setAmountToSend] = useState('');
  const [error, setError] = useState('');
  const [isTransferVisible, setIsTransferVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State for loader

  // Handle the Add Credits icon click
  const handleAddCredits = () => {
    navigate('/credits');
  };

  // Handle Withdrawals icon click
  const handleWithdrawals = () => {
    navigate('/withdrawals');
  };

  // Handle phone number input change
  const handlePhoneNumberChange = async (e) => {
    const value = e.target.value;
    setRecipientPhoneNumber(value);

    if (value.length === 11) {
      setIsLoading(true); // Show loader
      try {
        const response = await axios.get(`https://ampcds.onrender.com/api/payuser/${value}`);
        setRecipientName(response.data.fullName);
        setError('');
      } catch (err) {
        setError('Phone number not found.');
        setRecipientName('');
      } finally {
        setIsLoading(false); // Hide loader
      }
    } else {
      setRecipientName('');
    }
  };

  // Handle amount input change
  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmountToSend(value);

    if (parseInt(value) > runorUnits) {
      setError('Top-up Units.');
    } else {
      setError('');
    }
  };

  // Handle the transfer icon click
  const handleTransfer = async () => {
    if (!error && amountToSend && recipientPhoneNumber && recipientName) {
      try {
        await axios.post('https://ampcds.onrender.com/api/transfer', {
          senderPhoneNumber: phoneNumber,
          recipientPhoneNumber,
          amount: amountToSend,
          recipientName,
        });

        // Subtract the amount from the user's runorUnits and update local storage
        const updatedRunorUnits = runorUnits - parseInt(amountToSend, 10);
        const updatedUserData = { ...userData, runorUnits: updatedRunorUnits };
        localStorage.setItem('user', JSON.stringify(updatedUserData));

        alert('Transfer successful');

        // Refresh the page after successful transfer
        window.location.reload();

      } catch (err) {
        setError('Transfer failed.');
      }
    } else {
      setError('Please complete all fields.');
    }
  };

  return (
    <div className="container">
      <AccountOverview />
      {phoneNumber !== '07011560636' && (
        <div className="icon-container">
          <div className="icon-wrapper" onClick={handleAddCredits}>
            <FaPlus title="Add Credits" />
            <p className="icon-text">Topup Credits</p>
          </div>
          <div className="icon-wrapper" onClick={() => setIsTransferVisible(!isTransferVisible)}>
            <FaExchangeAlt title="Transfer Credits" />
            <p className="icon-text">Transfer Credits</p>
          </div>
          <div className="icon-wrapper" onClick={handleWithdrawals}>
            <FaMoneyCheckAlt title="Withdrawals" />
            <p className="icon-text">Withdraw</p>
          </div>
        </div>
      )}

      {isTransferVisible && (
        <div className="transfer-section">
          <input
            type="tel"
            value={recipientPhoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Enter recipient phone number"
            className="input"
          />
          {isLoading ? (
            <div className="loader"></div>
          ) : (
            recipientName && (
              <p className="recipient-name">{recipientName}</p>
            )
          )}
          <input
            type="number"
            value={amountToSend}
            onChange={handleAmountChange}
            placeholder="Enter amount"
            className="input"
          />
          {error && <p className="error">{error}</p>}
          <button onClick={handleTransfer} className="submit-button">
            Submit
          </button>
        </div>
      )}
      <TransfersAndPayments />
    </div>
  );
};

export default UserProfile;
