import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import JobCard from '../cards/JobCard';
import JobSkeleton from '../components/JobSkeleton';
import MarketingPop from '../components/MarketingPop';
import Fuse from 'fuse.js';
import styles from '../styles/JobList.css';

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false); // To track search loading
  const [searchTerm, setSearchTerm] = useState('');
  const [fetchError, setFetchError] = useState(null);
  const navigate = useNavigate();
  const jobRefs = useRef({});

  const user = JSON.parse(localStorage.getItem('user'));
  const isSubscribed = user?.isSubscribed;

  useEffect(() => {
    const localJobs = JSON.parse(localStorage.getItem('jobs')) || [];
    const filteredLocalJobs = localJobs.filter((job) => job && !job.taken);
    setJobs(filteredLocalJobs);
    fetchJobs();

    const lastJobId = localStorage.getItem('lastClickedJob');
    if (lastJobId) {
      const scrollToJob = () => {
        const jobElement = jobRefs.current && jobRefs.current[lastJobId];
        if (jobElement) {
          jobElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          localStorage.removeItem('lastClickedJob');
        }
      };

      const observer = new MutationObserver(() => {
        const jobElement = jobRefs.current && jobRefs.current[lastJobId];
        if (jobElement) {
          scrollToJob();
          observer.disconnect();
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
      setTimeout(scrollToJob, 500);
    }
  }, []);

  const fetchJobs = async () => {
    setIsLoading(true);
    setFetchError(null);
    try {
      const response = await fetch('https://ampcds.onrender.com/jobs/get-job');
      if (!response.ok) {
        throw new Error('Failed to fetch jobs');
      }
      const data = await response.json();
      const validJobs = data.filter((job) => job && !job.taken);
      const sortedJobs = validJobs.sort((a, b) => {
        // 1. Promoted jobs take precedence
        if (a.isPromoted && !b.isPromoted) return -1;
        if (!a.isPromoted && b.isPromoted) return 1;

        // 2. More recent jobs rank higher
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (dateB - dateA !== 0) return dateB - dateA;

        // 3. Calculate comparable budget values
        const budgetA = a.minBudget && a.maxBudget
          ? (a.minBudget + a.maxBudget) / 2 // Average of min and max
          : a.minBudget || a.maxBudget || 0; // Use min or max if only one is present
        const budgetB = b.minBudget && b.maxBudget
          ? (b.minBudget + b.maxBudget) / 2
          : b.minBudget || b.maxBudget || 0;

        // 4. Higher budgets rank higher
        if (budgetB - budgetA !== 0) return budgetB - budgetA;

        // 5. Default order (fallback)
        return 0;
      });

      setJobs((prevJobs) => [...prevJobs, ...sortedJobs]);
      localStorage.setItem('jobs', JSON.stringify(sortedJobs));
    } catch (error) {
      console.error('Error fetching new job listings:', error);
      setFetchError('Failed to fetch jobs. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewButtonClick = (jobId) => {
    if (!searchTerm || isSubscribed) {
      localStorage.setItem('lastClickedJob', jobId);
      navigate(`/job-details/${jobId}`);
    } else if (!isSubscribed) {
      navigate('/upgrade-page');
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  };

  const handleCallJob = async (jobId) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      alert('Please log in to apply for jobs.');
      return;
    }

    const { _id: userId, phoneNumber } = user;
    try {
      const response = await fetch(
        `https://ampcds.onrender.com/jobs/call-job-and-increase-count/${jobId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, phoneNumber }),
        }
      );

      if (response.ok) {
        alert('Application sent successfully!');
        return true;
      } else {
        throw new Error('Failed to send application');
      }
    } catch (error) {
      console.error('Error sending application:', error);
      alert('Something went wrong. Please try again later.');
      return false;
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSearchLoading(true);
    setTimeout(() => {
      setSearchLoading(false);
    }, 500); // Simulating search delay
  };

  const filteredJobs = useMemo(() => {
    if (!searchTerm) return jobs;
    const options = {
      keys: ['description', 'creator'],
      threshold: 0.4,
    };
    const fuse = new Fuse(jobs, options);
    return fuse.search(searchTerm).map(({ item }) => item);
  }, [searchTerm, jobs]);

  return (
    <div className={styles.container}>
      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="Search for jobs..."
          value={searchTerm}
          onChange={handleSearchChange}
          className={styles.searchInput}
        />
      </div>

      {fetchError && <p className={styles.error}>{fetchError}</p>}

      {(isLoading || searchLoading) && (
        <section className={styles.skeletonSection}>
          {Array.from({ length: 7 }).map((_, index) => (
            <JobSkeleton key={index} />
          ))}
        </section>
      )}

      {!isLoading && !searchLoading && jobs.length > 0 && filteredJobs.length === 0 && (
        <p className={styles.noJobsMessage}>No jobs found matching your search.</p>
      )}

      {!isLoading && !searchLoading && (
        <section className={styles.jobSection}>
          {filteredJobs.map((job, index) => (
            <React.Fragment key={job._id || index}>
              <div
                ref={(el) => {
                  if (el) jobRefs.current[job._id] = el;
                }}
              >
                <JobCard
                  job={job}
                  handleViewButtonClick={handleViewButtonClick}
                  handleCallJob={handleCallJob}
                  formatCurrency={formatCurrency}
                />
              </div>

              {!isSubscribed && index > 0 && index % 9 === 0 && <MarketingPop />}
            </React.Fragment>
          ))}
        </section>
      )}
    </div>
  );
};

export default JobList;
